import React, { useState, useEffect } from 'react';
import JournalCard from '../../component/JournalCard';
import axios from 'axios';
import toast from 'react-hot-toast';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const AllJournal = () => {
  const [journals, setJournals] = useState([]);

  const getAllJournals = async () => {
    try {
      const headers = {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

      const response = await axios.get(`${baseURL}/api/v1/admin/getAllJournals`, { headers });

      if (response.status === 200) {
        // Assuming the response.data contains the array of journals
        // console.log(response.data.data);
        
        const journal=response.data.data.filter(journal => journal.status !== 'accepted' && journal.status !== 'rejected');
        setJournals(journal);
        toast.success('Data Fetched Successfully');
      } else {
        toast.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Some internal server error');
    }
  };

  useEffect(() => {
    getAllJournals();
  }, []);

  return (
    <div className='journal-list'>
      {journals.length > 0 ? (
        journals.map((journal) => (
          <JournalCard key={journal._id} journal={journal} />
        ))
      ) : (
        <p>No journals available.</p>
      )}
    </div>
  );
};

export default AllJournal;
